import React, {useState} from "react";
import { navigate } from "@reach/router"
import Slider from 'react-input-slider';
import qs, { stringify } from 'qs';

import Layout from "../components/layout";
import SEO from "../components/seo";
import { questions } from "../static/questions";
import { Link } from "gatsby";
import { getPrevPageUrl, getNextPageUrl, buildModel, getQueryParam, getFormData } from "../util";

import { FaChevronRight, FaChevronDown, FaCheck, FaSync } from "react-icons/fa";
import axios from "axios";

function QuestionPage(props) {
  const [state, setState] = useState(() => {
    let stateModel = {showNotes: false};
    if(!props.category) {
      navigate(`/questions`);
      return stateModel;
    }
    const section = questions.find(o =>  o.category.toLowerCase() == (props.category || "").toLowerCase());
    if (!section) {
      navigate(`/questions`);
      return stateModel;
    }

    const question = section.questions.find((o, i) => i ==  parseInt(props.id) - 1);
    if(!question) {
      navigate(`/questions/${section.category.toLowerCase()}/1`);
      return stateModel;
    }

    var query = qs.parse(props.location.search, { ignoreQueryPrefix: true }).q;
    var model = buildModel(query);
    return {...stateModel, ...model};
  });

  let formFlareLoadingIcon = (state.pending) ? (<FaSync className="inline ml-2 spin" />) : (<></>);

  const nextQuestion = async () => {
    submitToFormFlare(state.submissionId)
      .then(() => {
        navigate(nextPage+getQueryParam(state));
      });
  }

  const submitToFormFlare = async (submissionId = null) => {
    return new Promise(async (resolve, reject) => {
      console.log("Submitting to form flare...");
      setState({...state, pending: true});
      let data = await getFormData(state);
      setState({...state, error: !data.isValid, errorFields: data.errorFields});
      // const pendingUrl = 'http://account.formflare.io/0cfcf3f2-9760-410a-56c0-08d93661b6c8';
      const pendingUrl = 'https://account.formflare.io/7ec93f0d-183e-43d5-56c1-08d93661b6c8'
      let baseUrl = pendingUrl;
      if(submissionId) {
        baseUrl = baseUrl + "/" + submissionId;
      }
      // const baseUrl = "https://account.formflare.io/ccbd91e4-7673-4127-d059-08d7d0cd9703";
      axios({
        method: "post",
        url: baseUrl,
        data: data.formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response);
          setState({...state, pending: false, success: true, submissionId: response.data.id});
          resolve();
        })
        .catch(function (response) {
          //handle error
          console.log("Submission exception");
          console.error(response);
          setState({...state, pending: false, success: false});
          reject();
        });
    });
  }

  const handleNumericInputChange = (key, value) => {
    setState({...state, [key]: parseInt(value) });
  }

  const handleStringInputChange = (key, value) => {
    setState({...state, [key]: value });
  }

  const toggleNotes = event => {
    setState({...state, showNotes: !state.showNotes });
  }

  const sliderStyle = {
    track: {
      width: '100%',
      backgroundColor: '#a0aec0'
    },
    active: {
      backgroundColor: '#53b035'
    }
  }

  const section = questions.find(o =>  o.category.toLowerCase() == (props.category || "").toLowerCase());
  if (!section) {
    navigate(`/questions`);
    return (<></>);
  }

  const question = section.questions.find((o, i) => i ==  parseInt(props.id) - 1);
  if(!question) {
    navigate(`/questions/${section.category.toLowerCase()}/1`);
    return (<></>);
  }

  

  // Find previous indexes
  var prevPage = getPrevPageUrl(props.id, section);
  var nextPage = getNextPageUrl(props.id, section);

  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />
      <article className="text-left my-2 md:my-6 shadow-lg border border-grey-500 p-6 rounded flex flex-col question-container">
        <h2 className="heading-2">{section.category}</h2>
        <div className="block mb-4 animate-fade-children-up flex flex-col flex-grow">
          <label className="text-gray-700 form-label mb-2 mt-6 block" htmlFor={question.key + "-n"}>{question.label}</label>
          <label className="text-gray-700 text-xs" htmlFor={question.key + "-n"}>Now</label>
          <div className="flex items-center mb-2">
            <Slider 
              axis="x"
              xstep={1}
              xmin={0}
              xmax={10}
              x={state.hasOwnProperty(question.key + "-n") ? state[question.key + "-n"] : 5 }
              onChange={({ x }) => handleNumericInputChange(question.key + "-n", x) }
              styles={sliderStyle}
            />
            <input type="text" className="form-input ml-3 w-12" id={question.key + "-n"} value={state.hasOwnProperty(question.key + "-n") ? state[question.key + "-n"] : 5} onChange={(event) => handleNumericInputChange(question.key + "-n", event.target.value) } />
          </div>
          <label className="text-gray-700 text-xs" htmlFor={question.key + "-f"}>Future</label>
          <div className="flex items-center mb-2">
            <Slider 
              axis="x"
              xstep={1}
              xmin={0}
              xmax={10}
              x={state.hasOwnProperty(question.key + "-f") ? state[question.key + "-f"] : 5 }
              onChange={({ x }) => handleNumericInputChange(question.key + "-f", x) }
              styles={sliderStyle}
            />
            <input type="text" className="form-input ml-3 w-12" id={question.key + "-f"} value={state.hasOwnProperty(question.key + "-f") ? state[question.key + "-f"] : 5} onChange={(event) => handleNumericInputChange(question.key + "-f", event.target.value) } />
          </div>
          <div className="flex mb-2 text-sm text-gray-700">
            <div className="w-full mr-2 max-w-xl">{question.minLabel}</div>
            <div className="w-full ml-2 max-w-xl text-right">{question.maxLabel}</div>
            <div className="w-32 ml-2"></div>
          </div>
          <div className="md:flex text-sm text-gray-700 mt-6 text-center flex-grow items-end">
            <div className="w-full max-w-xl md:text-right">
              {/* <Link className="block md:inline-block no-underline text-white bg-primary hover:bg-secondary transition-bg py-2 px-3 rounded-md" to={nextPage+getQueryParam(state)}>Next Question</Link> */}
              <a onClick={nextQuestion} className="block md:inline-block no-underline text-white bg-primary hover:bg-secondary transition-bg py-2 px-3 rounded-md">Next Question {formFlareLoadingIcon}</a>
            </div>
            <div className="w-full max-w-xl  md:text-left order-first">
              <Link className="block md:inline-block no-underline md:text-white md:bg-gray-500 md:hover:bg-gray-700 transition-bg py-2 px-3 rounded-md" to={prevPage+getQueryParam(state)}>Back</Link>
            </div>
          </div>
        </div>
        {/* <div>
          <code>{nextPage+getQueryParam(state)}</code>
          <code>
            {JSON.stringify(state, null, "  ")}
          </code>
        </div> */}
      </article>
      <div className="w-full text-right px-6">
        <a className="text-blue-500 inline-flex items-center cursor-pointer" onClick={toggleNotes}>{state.showNotes ? <FaChevronDown className="inline text-xs" /> : <FaChevronRight  className="inline text-xs" /> }&nbsp;Notes</a> | <Link className="text-blue-500" to={`/results${getQueryParam(state)}`}>View Results</Link>
      </div>
      <div className={["w-full text-right px-6", state.showNotes ? "block" : "hidden"].join(" ")}>
        <textarea className="border w-full" rows="5" maxLength="512" value={state.hasOwnProperty(question.key + "-notes") ? state[question.key + "-notes"] : ""} onChange={event => handleStringInputChange(question.key + "-notes", event.target.value) } />
        <div className="text-gray-500 text-right">{(state.hasOwnProperty(question.key + "-notes") ? state[question.key + "-notes"] : "").length}/512</div>
      </div>
    </Layout>
  )
}

export default QuestionPage;