import React from 'react';
import { Router, Redirect } from "@reach/router";

import QuestionPage from './question';
import ProfilePage from './profile';

function QuestionsPage() {
  return (
    <Router>
      <QuestionPage path="questions/:category/:id" />
      <ProfilePage path="questions/profile" />
      {/* <Redirect default to="questions/strategy/1" /> */}
    </Router>
  );
}

export default QuestionsPage;